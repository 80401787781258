<template>
  <div v-if="show">
    <b-alert
      v-if="error"
      variant="danger"
      class="mb-1"
      show
    >
      <div class="alert-body">
        Ada Rekomendasi Tidak Ditemukan
      </div>
    </b-alert>
    <h6>{{ statusRekomendasi }}</h6>
    <b-list-group
      class="mb-1"
      flush
    >
      <b-list-group-item
        v-for="(data, index) in listRekomendasi"
        :key="index"
        class="px-0 py-50"
      >
        {{ data }}
      </b-list-group-item>
    </b-list-group>
  </div>
</template>

<script>
import {
  BListGroup, BListGroupItem, BAlert,
} from 'bootstrap-vue'

export default {
  components: {
    BListGroup,
    BListGroupItem,
    BAlert,
  },
  props: {
    statusReko: {
      type: Number,
      default: 0,
    },
    rekapDetail: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      show: false,
      error: false,
      statusRekomendasi: '',
      listRekomendasi: [],
    }
  },
  watch: {
    statusReko() {
      this.setRekomendasi()
    },
    rekapDetail() {
      this.getRekomendasi()
    },
  },
  mounted() {
    this.getRekomendasi()
  },
  methods: {
    setRekomendasi() {
      if (this.statusReko === 1) {
        this.statusRekomendasi = 'Dilanjutkan'
      } else if (this.statusReko === -1) {
        this.statusRekomendasi = 'Tidak Dilanjutkan'
      } else {
        this.error = true
      }
    },
    getRekomendasi() {
      if (this.rekapDetail) {
        this.setRekomendasi()
        this.listRekomendasi = []
        this.rekapDetail.map(values => {
          if (values.is_continue === null) {
            this.error = true
          }
          // if (values.is_continue === null) {
          //   this.error = true
          // } else if (values.is_continue === 1) {
          //   this.statusRekomendasi = 'Dilanjutkan'
          // } else {
          //   this.statusRekomendasi = 'Tidak Dilanjutkan'
          // }
          this.listRekomendasi.push(values.recommendation)
          return true
        })
        this.show = true
      }
    },
  },
}
</script>
